import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import AccordianRow from '../../components/AccordianRow/AccordianRow';

const BuilderFaqPage = () => {
	const intl = useIntl();
	return (
		<Layout
			view='builder'
			subheader={<FormattedMessage id='nav.builderDashB' />}
			header={
				<span className='bold'>
					{' '}
					<FormattedMessage id='builderFAQ.pageHeader' />{' '}
				</span>
			}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.faqDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'builderFAQ.pageHeader' })}
			/>
			<Container>
				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='builderFAQ.headerF' />
						</h2>
						<p>
							<FormattedMessage id='builderFAQ.contentFi' />
						</p>
						<p>
							<FormattedMessage id='builderFAQ.contentFii' />
						</p>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q23H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a23' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q24H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a24' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q25H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a25a' />
								<ul>
									<li>
										<FormattedMessage id='builderFAQ.a25b' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a25c' />
									</li>
								</ul>
								<FormattedMessage id='builderFAQ.a25d' />
								<ul>
									<li>
										<FormattedMessage id='builderFAQ.a25e' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a25f' />
									</li>
								</ul>
								<FormattedMessage id='builderFAQ.a25g' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q26H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a26a' />{' '}
								<a href='https://builderportal.hcraontario.ca/en-US/'>
									<FormattedMessage id='builderFAQ.a26b' />
								</a>{' '}
								<FormattedMessage id='builderFAQ.a26c' />{' '}
								<a href='https://builderportal.hcraontario.ca/en-US/'>
									<FormattedMessage id='builderFAQ.a26b' />
								</a>{' '}
								.
								<br />
								<FormattedMessage id='builderFAQ.a26d' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>{' '}
								<FormattedMessage id='builderFAQ.a26e' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q27H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a27a' />
							</p>
							<p>
								<FormattedMessage id='builderFAQ.a27b' />{' '}
								<i>
									<FormattedMessage id='builderFAQ.a27c' />
								</i>{' '}
								<FormattedMessage id='builderFAQ.a27d' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q28H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a28a' />{' '}
								<a href='mailto:customerservice@tarion.com'>customerservice@tarion.com</a>
								<FormattedMessage id='builderFAQ.a28b' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q29H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a29a' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>{' '}
								<FormattedMessage id='builderFAQ.a29b' />
							</p>
						</AccordianRow>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q30H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a30a' />
								<ul>
									<li>
										<FormattedMessage id='builderFAQ.a30b' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30c' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30d' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30e' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30f' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30g' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30h' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30i' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a30j' />
									</li>
								</ul>
							</p>
							<p>
								<FormattedMessage id='builderFAQ.a30k' />
							</p>
							<p>
								<FormattedMessage id='builderFAQ.a30l' />
							</p>
							<p>
								<FormattedMessage id='builderFAQ.a30m' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>{' '}
								<FormattedMessage id='builderFAQ.a30n' />
							</p>
						</AccordianRow>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							{' '}
							<FormattedMessage id='builderFAQ.headerA' />{' '}
						</h2>
						<AccordianRow heading={<FormattedMessage id='builderFAQ.q1H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a1a' />{' '}
								<a
									href={`../../../../${intl.formatMessage({
										id: 'linkNames.newInstructionLink'
									})}`}
									aria-label={intl.formatMessage({
										id: 'builderFAQ.a1b'
									})}
								>
									<FormattedMessage id='builderFAQ.a1b' />
								</a>
								. <FormattedMessage id='builderFAQ.a1c' />{' '}
								<Link to='/builder-vendor/new-application-checklist/'>
									<FormattedMessage id='builderFAQ.a1d' />
								</Link>{' '}
								<FormattedMessage id='builderFAQ.a1e' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q2H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a2e' />{' '}
								<b>
									<FormattedMessage id='builderFAQ.a2c' />
								</b>
								. <FormattedMessage id='builderFAQ.a2d' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a2f' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q14H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a14a' />{' '}
								<ol>
									<li>
										<FormattedMessage id='builderFAQ.a14b' />{' '}
										<a href='https://www.tarion.com/builders/registrar-bulletins'>
											<FormattedMessage id='builderFAQ.a14c' />
										</a>
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a14d' />{' '}
										<a href='https://builderlink.tarion.com/s/login/?ec=302&startURL=%2Fs%2F'>
											<FormattedMessage id='builderFAQ.a14e' />
										</a>{' '}
										<FormattedMessage id='builderFAQ.a14f' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a14g' />{' '}
										<a href='https://www.tarion.com/qualification-enrolment-process'>
											<FormattedMessage id='builderFAQ.a14h' />
										</a>
									</li>
								</ol>
							</p>
						</AccordianRow>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='builderFAQ.headerB' />
						</h2>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q3H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a3a' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a3b' />{' '}
								<b>
									<FormattedMessage id='builderFAQ.a3c' />
								</b>
								.
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q2H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a2a' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a2b' />{' '}
								<b>
									<FormattedMessage id='builderFAQ.a2c' />
								</b>
								. <FormattedMessage id='builderFAQ.a2d' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a2g' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q4H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a4a' />{' '}
								<a
									href='https://builderportal.hcraontario.ca/'
									aria-label={intl.formatMessage({
										id: 'builderFAQ.a4b'
									})}
								>
									<FormattedMessage id='builderFAQ.a4b' />
								</a>
								. <FormattedMessage id='builderFAQ.a4c' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a4d' />{' '}
								<a
									href={`../../../${intl.formatMessage({
										id: 'linkNames.renewInstructionLink'
									})}`}
									aria-label={intl.formatMessage({
										id: 'builderFAQ.a4e'
									})}
								>
									<FormattedMessage id='builderFAQ.a4e' />
								</a>
								. <FormattedMessage id='builderFAQ.a4f' />{' '}
								<Link to='/builder-vendor/renewal-application-checklist/'>
									<FormattedMessage id='builderFAQ.a4g' />
								</Link>{' '}
								<FormattedMessage id='builderFAQ.a4h' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a1f' />{' '}
								<a
									href='https://obd.hcraontario.ca/'
									aria-label={intl.formatMessage({
										id: 'linkNames.obd'
									})}
								>
									<FormattedMessage id='linkNames.obd' />
								</a>{' '}
								<FormattedMessage id='builderFAQ.a1g' /> <a href='https://www.tarion.com/'>Tarion</a>{' '}
								<FormattedMessage id='builderFAQ.a1h' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q5H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a5a' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a5b' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q6H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a6a' />{' '}
								<a
									href='https://builderportal.hcraontario.ca/'
									aria-label={intl.formatMessage({
										id: 'builderFAQ.a6b'
									})}
								>
									<FormattedMessage id='builderFAQ.a6b' />
								</a>{' '}
								<FormattedMessage id='builderFAQ.a6c' />
								<ul>
									<li>
										<FormattedMessage id='builderFAQ.a6lia' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a6lib' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a6lic' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a6lid' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a6lie' />
									</li>
									<li>
										<FormattedMessage id='builderFAQ.a6lif' />
									</li>
								</ul>
								<FormattedMessage id='builderFAQ.a6d' />{' '}
								<b>
									<FormattedMessage id='builderFAQ.a6e' />
								</b>{' '}
								<FormattedMessage id='builderFAQ.a6f' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a6g' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a6h' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a6i' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.a7H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a7a' />{' '}
								<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
							</p>
						</AccordianRow>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='builderFAQ.headerC' />
						</h2>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q8H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a8a' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a1f' />{' '}
								<a
									href='https://obd.hcraontario.ca/'
									aria-label={intl.formatMessage({
										id: 'linkNames.obd'
									})}
								>
									<FormattedMessage id='linkNames.obd' />
								</a>{' '}
								<FormattedMessage id='builderFAQ.a1g' /> <a href='https://www.tarion.com/'>Tarion</a>{' '}
								<FormattedMessage id='builderFAQ.a1h' />
							</p>
						</AccordianRow>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='builderFAQ.headerD' />
						</h2>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q9H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a9a' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a9b' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q10H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a10a' />{' '}
								<Link to='/builder-vendor/become-a-licensed-builder/before-you-apply/'>
									<FormattedMessage id='builderFAQ.a10b' />
								</Link>
								. <FormattedMessage id='builderFAQ.a10c' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a10d' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q11H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a11a' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q12H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a12a' />
							</p>
						</AccordianRow>

						<AccordianRow heading={<FormattedMessage id='builderFAQ.q13H' />}>
							<p>
								<FormattedMessage id='builderFAQ.a13a' />{' '}
								<Link to='/builder-vendor/become-a-licensed-builder/before-you-apply/'>
									<FormattedMessage id='builderFAQ.a13b' />
								</Link>{' '}
								<FormattedMessage id='builderFAQ.a13c' />
								<br />
								<br />
								<FormattedMessage id='builderFAQ.a13d' />
							</p>
						</AccordianRow>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BuilderFaqPage;
